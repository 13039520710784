import React from "react";
import GitHub from "../assets/github.png";
import "./Home.css";
import Linkedin from "../assets/linkedin.png";
import Twitter from "../assets/twitter.png";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#0a192f] flex justify-center items-center p-4 contact_allign"
    >
      <div className="pb-8 ">
        <p className="text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300">
          Contact
        </p>
        <p className="text-gray-300 py-4 max-w-[930px]">
          If you want to know more about me or my work, or if you would just
          like to say hello, send me a message. I'd love to hear from you. You
          can also shoot me an email at ✉ albinluckose021@gmail.com or feel free to call me on +91 9048857704
        </p>
      </div>
      <div className="contact_section">
        <div>
          <a href="https://github.com/Albin05" target="_blank">
            <img src={GitHub} alt="github" />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/albin-baby-5930a8232/"
            target="_blank"
          >
            <img src={Linkedin} alt="linkedin" />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/albin_baby5" target="_blank">
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
