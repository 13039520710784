import React from "react";
import fitmeals from "../assets/fitmeals.gif";
import bebodywise from "../assets/bebodywise.gif";
import milaap from "../assets/milaap.gif";
import "./Home.css";

const Work = () => {
  return (
    <div
      name="work"
      className="w-full h-screen text-gray-300 bg-[#0a192f] screenheight"
    >
      <div className="max-w-[1100px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600">
            Projects
          </p>
          <p className="py-6">Check out some of my recent work</p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-5">
          {/* Grid Item */}
          <div>
            <div
              style={{ backgroundImage: `url(${milaap})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  React, Redux & Chakra UI
                </span>
                <div className="pt-8 text-center">
                  <a href="https://milaap.vercel.app/" target="_blank">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a
                    href="https://github.com/sarb908/greasy-sofa-2441"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="project_description">
              <h3 className="project_heading">1. Milaap</h3>
              Tech stack used is React, Redux & Chakra UI. Functionalities such
              as User authentication, sorting and filtering are included. Chakra
              UI has been used to make the UI more consistent and attractive
              throughout all the pages. The website is very responsive hence it
              supports the display of all sizes.
            </p>
          </div>

          <div>
            <div
              style={{ backgroundImage: `url(${bebodywise})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  Advanced JavaScript
                </span>
                <div className="pt-8 text-center">
                  <a
                    href="https://bebodywisediversityclone.netlify.app/index.html"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a
                    href="https://github.com/Albin05/Bebodywise-frontend-U4"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="project_description">
              <h3 className="project_heading">2. Be Bodywise (Frontend)</h3>
              The tech stack used is HTML5, CSS3, ES6 JavaScript & Bootstrap.
              Functionalities such as add to cart, checkout, login & signup are
              added. The products data is fetched from an API. Also used
              bootstrap to implement the sliders on the homepage.
            </p>
          </div>
          <div>
            <div
              style={{ backgroundImage: `url(${fitmeals})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  HTML, CSS & JavaScript
                </span>
                <div className="pt-8 text-center">
                  <a
                    href="https://fitmeals-project.netlify.app/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="https://github.com/Albin05/Fitmeals" target="_blank">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="project_description">
              <h3 className="project_heading">3. Fitmeals</h3>
              Tech stack used is HTML, CSS & JavaScript. Functionalities such as
              add to cart, checkout page, login & signup are added. Advanced css
              animations are added on the homepage to make the UI more
              attractive. The website is very responsive hence it supports
              display of all the sizes. Hamburger menu has been implemented so
              that the mobile users can navigate through pages very easily.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
