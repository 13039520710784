import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import Logo from "../assets/home-main.png";
import Typewriter from "typewriter-effect";
import "./Home.css";

const Home = () => {
  const myStyle = {
    margin: "10px auto",
  };

  return (
    <div
      name="home"
      className="w-full h-screen bg-[#0a192f]"
      style={{ paddingTop: "120px" }}
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-pink-600">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Albin Baby
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          <Typewriter
            options={{
              strings: ["I'm a Full Stack Developer."],
              autoStart: true,
              loop: true,
            }}
          />
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[1000px]">
          A full-stack developer with hands-on experience with building (and
          occasionally designing) exceptional digital experiences. Currently,
          I’m focused on building responsive full-stack web applications with MERN stack.
        </p>
        <img src={Logo} alt="" width="200px" style={myStyle} className="zoom" />
        <div>
          <a
            href="https://drive.google.com/file/d/1D4zH99pRtLB6B-qi4WN1SmDRaL-juLsD/view?usp=sharing"
            target="_blank"
          >
            <button
              style={myStyle}
              className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600"
            >
              Download Resume
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
