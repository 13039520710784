import React from 'react';

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#1a1328] text-gray-300">
      <div className="max-w-[960px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="max-w-[960px] w-full flex flex-col gap-8">
          <div>
            <p className="text-4xl font-bold inline border-b-4 border-pink-600">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[960px] w-full flex">
          <div className="sm:text-left text-4xl font-bold">
            <p>Hi. I'm Albin, nice to meet you. Please take a look around.</p>
          </div>
        </div>
        <div className="text-gray-300 py-4">
          <p>
            I am passionate about building excellent software that improves the
            lives of those around me. I specialize in creating software for
            clients ranging from individuals and small-businesses all the way to
            large enterprise corporations. What would you do if you had a
            software expert available at your fingertips?
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
